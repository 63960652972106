import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import { AttachFile, Colorize, DeleteOutline } from "@material-ui/icons";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdornment: {
    width: 20,
    height: 20,
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Campo obrigatório"),
  color: Yup.string().min(3, "Muito curto!").max(9, "Muito longo!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    outOfHoursMessage: "",
    orderQueue: "",
    integrationId: "",
    promptId: "",
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const greetingRef = useRef();
  const [integrations, setIntegrations] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [schedules, setSchedules] = useState([
    { weekday: "Segunda-feira", weekdayEn: "monday", startTime: "08:00", endTime: "18:00" },
    { weekday: "Terça-feira", weekdayEn: "tuesday", startTime: "08:00", endTime: "18:00" },
    // ... restante dos dias
  ]);

  // Ajuste para definir a função setPrompts
  const [prompts, setPrompts] = useState([]); // Adicionado para evitar o erro 'setPrompts is not defined'

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        setPrompts(data.prompts);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const scheduleType = data.find((d) => d.key === "scheduleType");
        setSchedulesEnabled(scheduleType && scheduleType.value === "queue");
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queueIntegration");
        setIntegrations(data.queueIntegrations);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!queueId) return;
    (async () => {
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => ({ ...prevState, ...data }));
        setSelectedPrompt(data.promptId || null);
        setSchedules(data.schedules);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [queueId, open]);

  const handleAttachmentFile = (e) => {
    const file = e.target.files[0];
    setAttachment(file || null);
  };

  const handleSaveQueue = async (values) => {
    try {
      const formData = new FormData();
      if (queueId) {
        await api.put(`/queue/${queueId}`, {
          ...values,
          schedules,
          promptId: selectedPrompt || null,
        });
      } else {
        const { data } = await api.post("/queue", {
          ...values,
          schedules,
          promptId: selectedPrompt || null,
        });
        if (attachment) formData.append("file", attachment);
        await api.post(`/queue/${data.id}/media-upload`, formData);
      }
      toast.success("Fila salva com sucesso");
      handleClose(); // **Erro resolvido**: Agora handleClose é chamado corretamente
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangePrompt = (e) => {
    setSelectedPrompt(e.target.value);
  };

  // **Definindo handleClose**: Adicionei a função handleClose para fechar o modal
  const handleClose = () => {
    setQueue(initialState); // Reseta o estado da fila
    setSelectedPrompt(null); // Reseta o prompt selecionado
    onClose(); // Chama a função onClose recebida como prop
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>
        {queueId ? i18n.t("queueModal.title.edit") : i18n.t("queueModal.title.add")}
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={queue}
          enableReinitialize
          validationSchema={QueueSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQueue(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <Field
                as={TextField}
                label={i18n.t("queueModal.form.name")}
                autoFocus
                name="name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                variant="outlined"
                margin="dense"
                className={classes.textField}
              />
              <Field
                as={TextField}
                label={i18n.t("queueModal.form.color")}
                name="color"
                onFocus={() => setColorPickerModalOpen(true)}
                error={touched.color && Boolean(errors.color)}
                helperText={touched.color && errors.color}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ backgroundColor: values.color }} className={classes.colorAdornment}></div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton size="small" onClick={() => setColorPickerModalOpen(true)}>
                      <Colorize />
                    </IconButton>
                  ),
                }}
                variant="outlined"
                margin="dense"
                className={classes.textField}
              />
              <ColorPicker
                open={colorPickerModalOpen}
                handleClose={() => setColorPickerModalOpen(false)}
                onChange={(color) => setQueue((prev) => ({ ...prev, color }))}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default QueueModal;
