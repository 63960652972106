import React, { useState, useEffect } from "react";
import qs from 'query-string';
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputMask from 'react-input-mask';
import api from "../../services/api";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";

// Componente de copyright
const Copyright = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="#">
				PLW
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
};

// Estilos para o componente
const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%",
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

// Validação do formulário usando Yup
const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!") // Mensagem de erro para nome muito curto
		.max(50, "Too Long!") // Mensagem de erro para nome muito longo
		.required("Required"), // Campo obrigatório
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"), // Validação de senha
	email: Yup.string().email("Invalid email").required("Required"), // Validação de email
});

// Componente SignUp
const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();
	const [allowregister, setallowregister] = useState('enabled'); // Estado para permitir registro
	const [trial, settrial] = useState('3'); // Estado para dias de teste

	useEffect(() => {
		fetchallowregister(); // Chama a função para buscar permissão de registro
		fetchtrial(); // Chama a função para buscar os dias de teste
	}, []);

	// Função para buscar o valor de dias de teste
	const fetchtrial = async () => {
		try {
			const responsevvv = await api.get("/settings/trial"); // Requisição para buscar dias de teste
			const allowtrialX = responsevvv.data.value; // Valor recebido
			settrial(allowtrialX); // Atualiza o estado de dias de teste
		} catch (error) {
			console.error('Error retrieving trial', error); // Tratamento de erro
		}
	};

	// Função para buscar permissão de registro
	const fetchallowregister = async () => {
		try {
			const responsevv = await api.get("/settings/allowregister"); // Requisição para buscar permissão
			const allowregisterX = responsevv.data.value; // Valor recebido
			setallowregister(allowregisterX); // Atualiza o estado de permissão de registro
		} catch (error) {
			console.error('Error retrieving allowregister', error); // Tratamento de erro
		}
	};

	// Redireciona para a página de login se o registro estiver desabilitado
	if (allowregister === "disabled") {
		history.push("/login");
	}

	const initialState = { name: "", email: "", phone: "", password: "", planId: "disabled" }; // Estado inicial do formulário

	const [user] = useState(initialState); // Estado para o usuário
	const dueDate = moment().add(trial, "day").format(); // Calcula a data de vencimento

	// Função para lidar com o registro
	const handleSignUp = async values => {
		// Atualiza os valores com informações adicionais
		Object.assign(values, { recurrence: "MENSAL" });
		Object.assign(values, { dueDate: dueDate });
		Object.assign(values, { status: "t" });
		Object.assign(values, { campaignsEnabled: true });
		try {
			await openApi.post("/companies/cadastro", values); // Requisição para registrar a empresa
			toast.success(i18n.t("signup.toasts.success")); // Exibe mensagem de sucesso
			history.push("/login"); // Redireciona para a página de login
		} catch (err) {
			console.log(err); // Loga o erro
			toastError(err); // Exibe mensagem de erro
		}
	};

	const [plans, setPlans] = useState([]); // Estado para os planos
	const { register: listPlans } = usePlans(); // Hook para listar planos

	useEffect(() => {
		async function fetchData() {
			const list = await listPlans(); // Chama a função para listar planos
			setPlans(list); // Atualiza o estado com a lista de planos
		}
		fetchData(); // Chama a função fetchData
	}, [listPlans]); // Adicionando dependência listPlans

	const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png`; // URL da logo
	const randomValue = Math.random(); // Gera um número aleatório para evitar cache
	const logoWithRandom = `${logo}?r=${randomValue}`; // Adiciona o número aleatório à URL da logo

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<div>
					<img style={{ margin: "0 auto", width: "80%" }} src={logoWithRandom} alt={`${process.env.REACT_APP_NAME_SYSTEM}`} />
				</div>
				<Formik
					initialValues={user}
					enableReinitialize={true} // Permite reinitializar os valores
					validationSchema={UserSchema} // Aplica o esquema de validação
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSignUp(values); // Chama a função de registro
							actions.setSubmitting(false); // Define o estado de submissão como falso
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="name"
										name="name"
										error={touched.name && Boolean(errors.name)} // Verifica se há erro
										helperText={touched.name && errors.name} // Mensagem de erro
										variant="outlined"
										fullWidth
										id="name"
										label="Nome da Empresa" // Rótulo do campo
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="email"
										label={i18n.t("signup.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)} // Verifica se há erro
										helperText={touched.email && errors.email} // Mensagem de erro
										autoComplete="email"
										required
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={InputMask}
										mask="(99) 99999-9999" // Máscara para telefone
										variant="outlined"
										fullWidth
										id="phone"
										name="phone"
										error={touched.phone && Boolean(errors.phone)} // Verifica se há erro
										helperText={touched.phone && errors.phone} // Mensagem de erro
										autoComplete="phone"
										required
									>
										{({ field }) => (
											<TextField
												{...field}
												variant="outlined"
												label="Telefone"
												fullWidth
											/>
										)}
									</Field>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="password"
										label={i18n.t("signup.form.password")}
										type="password"
										error={touched.password && Boolean(errors.password)} // Verifica se há erro
										helperText={touched.password && errors.password} // Mensagem de erro
										required
									/>
								</Grid>

								<Grid item xs={12}>
									<FormControl variant="outlined" fullWidth>
										<InputLabel id="demo-simple-select-outlined-label">Planos</InputLabel>
										<Field
											as={Select}
											labelId="demo-simple-select-outlined-label"
											name="planId"
											id="planId"
											required
										>
											<MenuItem disabled value="disabled">Selecione um plano</MenuItem>
											{plans.map((plan) => (
												<MenuItem key={plan.id} value={plan.id}>
													{plan.name}
												</MenuItem>
											))}
										</Field>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={isSubmitting}>
										Criar conta
									</Button>
								</Grid>
							</Grid>

							<Grid container justifyContent="flex-end">
								<Grid item>
									<Link component={RouterLink} to="/login" variant="body2">
										Já tem uma conta? Faça login
									</Link>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	);
};

export default SignUp;
